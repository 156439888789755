@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
}


html {
    font-size: 100%;
} 
@-ms-viewport{
    width: 480px;
    }

body {
    margin: 0 auto;
    padding:0;
    font-family: 'Oswald', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    transition: ease-in-out;
    -webkit-text-size-adjust:none;
    text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}
.small {
    zoom: 75%;
  }
  /* .normal {
    zoom: normal;
  }
  .big {
    zoom: 2.5;
  } */

@tailwind base;
@tailwind components;
@tailwind utilities;